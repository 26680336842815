import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import acropolisBanner from '../images/acropolisBanner.jpg';
import { Breadcrumbs, Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  CardHeader, } from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import { FaCar } from "react-icons/fa";
import { BsBusFront } from "react-icons/bs";
import bnrimg1 from '../images/banner-img2.jpg';

const Direction = () => {
  return (
    <>
    <Nav/>
    <div className="hidden lg:block blur-sm">
      <img src={bnrimg1} alt="acropolis Banner" className="object-cover h-96 w-full ..."/>
    </div>
    <span className='hidden lg:block absolute top-60 left-[32rem] text-6xl py-8 px-6 border-y-8 border-white-900 text-white font-bold'>About Us      </span>
    <div className='flex justify-center z-10 my-5'>
      <Breadcrumbs
        separator={
          <ArrowLongRightIcon className="h-4 w-4 text-white" strokeWidth={2.5} />
        }
        className="rounded-full border border-white bg-gradient-to-tr from-gray-900 to-gray-800 p-1"
      >
        <a
          href="/"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          <HomeIcon className='w-5'/>
        </a>
        {/* <a
          href="#"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Components
        </a> */}
        <a
          href="/about-us"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          About
        </a>
      </Breadcrumbs>
    </div>
    <div className='container mx-auto px-8'>
          {/* aboutus */}
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">ABOUT US</Typography>
              <Typography variant="h1" className="mb-2 text-slate-400">
                TECH CLICK INDIA
              </Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
              {/* <Typography variant='h5' className='italic my-5'>
                Free Entry
              </Typography> */}
              <a href="#1" className="inline-block">
                <Button variant="text" className="flex items-center text-green-800 border-2 border-orange-800 bg-white">
                  Read More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </a>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
      {/* aboutus */}
      <div className='my-4'>
      <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">OUR VISION</h2>
        <p>To become the most valued & preferred Telecommunication
           infrastructure provider (O&M & Project) for our esteemed
            customers by surpassing the benchmark set by the industry
             with our operational excellence.</p>
    </div>
    <div className='my-4'>
             <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">OUR MISSION</h2>
        <p>To exceed the customer expectations by its quality of services & 
          to continuously improve its business process and services.</p>
    </div>
    <div className='my-4'>
             <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">AERAS OF SPECIALIZATION</h2>
        <ul className='list-disc'>
          <li>Operation & Maintenance of Network Services for – Tower,
             Medium Facilities, Fibre & Outdoor Small Cell
              (Managing Services of all Utility Equipment, Active/Electronic Equipment, MW, Transport & OFC Network)</li>
              <li>AMC for Information Projects</li>
              <li>Designing, Fabrication, Supply & Installation</li>
              <li>Optical Fibre Install & Maintenance</li>
              <li>IT Infrastructure Project Management</li>
              <li>Effective Cost Management & Optimization Skills</li>
        </ul>
    </div>
    <div className='my-4'>
    <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">WHY OUR CLIENTS CHOOSE US?</h2>
        <ul className='list-disc'>
          <li>Utilize our return of experience, best practices developed through execution of various telecom companies O&M projects</li>
              <li>Highly cost-effective solutions</li>
              <li>Improvement in system reliability and efficiency through integrated services approach</li>
              <li>Minimum turnaround time</li>
              <li>Skilled manpower</li>
              <li>Robust and well-defined process</li>
        </ul>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default Direction