import React, { Fragment, createElement, useEffect, useState } from "react";
import { Carousel, Typography, Button,Card,
  CardHeader,
  CardBody,
  CardFooter, Avatar, Chip,} from "@material-tailwind/react";
import Nav from '../components/Nav';
import Slider from '../components/Carousel';
import crocs from '../images/crocs.jpg';
import ethos from '../images/ETHOS.png';
import uspolo from '../images/uspolo.png';
import kfc from '../images/kfc.png';
import nykaa from '../images/nykaa.png';
import pizzahut from '../images/pizzahut.png';
import {
  CalendarDaysIcon,
  WifiIcon,
} from "@heroicons/react/24/solid";
import Footer from '../components/Footer';
import bnrimg1 from '../images/banner-img1.jpg';
import { GiCctvCamera, GiVideoConference } from "react-icons/gi";
import { FaEthernet } from "react-icons/fa";
import { FcWiFiLogo } from "react-icons/fc";
import { BsPersonVideo2 } from "react-icons/bs";
import { LiaDigitalTachographSolid } from "react-icons/lia";
import { FaHouseFire } from "react-icons/fa6";
import { TbDeviceLandlinePhone, TbHomeEco } from "react-icons/tb";
import { SiGoogleclassroom } from "react-icons/si";
import { MdOutlineSolarPower } from "react-icons/md";

const services = [
  {
    title: "CCTV",
    link:"/services#cctv",
    icon: GiCctvCamera,
  },
  {
    title: "Structured Networking",
    link:"/services#networking",
    icon: FaEthernet,
  },
  {
    title: "Wi-Fi Zone",
    link:"/services#wifi",
    icon: FcWiFiLogo,
  },
  {
    title: "Video Door Phone",
    link:"/services#videdoorphone",
    icon: BsPersonVideo2,
  },
  {
    title: "Access Control",
    link:"/services#accessControl",
    icon: LiaDigitalTachographSolid,
  },
  {
    title: "Fire & Intrusion Alarm System",
    link:"/services#fias",
    icon: FaHouseFire,
  },
  {
    title: "VoIP & Intercom",
    link:"/services#voipIntercom",
    icon: TbDeviceLandlinePhone,
  },
  {
    title: "Digital Classroom",
    link:"/services#Digiclass",
    icon: SiGoogleclassroom,
  },
  {
    title: "Audio & Video Conferencing",
    link:"/services#avcon",
    icon: GiVideoConference,
  },
  {
    title: "Solar Power Solution",
    link:"/services#solar",
    icon: MdOutlineSolarPower,
  },
  {
    title: "Home Automation",
    link:"/services#HomeAutomation",
    icon: TbHomeEco,
  },
    
];

const Home = () => {
  return (
    <>
    <Nav/>
    <Slider/>
    <div className="container mx-auto px-5">
      
      {/* aboutus */}
      <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">ABOUT US</Typography>
              <Typography variant="h1" className="mb-2 text-slate-400">
                TECH CLICK INDIA
              </Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
              {/* <Typography variant='h5' className='italic my-5'>
                Free Entry
              </Typography> */}
              <a href="#1" className="inline-block">
                <Button variant="text" className="flex items-center text-green-800 border-2 border-orange-800 bg-white">
                  Read More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </a>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
      {/* aboutus */}
      {/* services */}
        <div className="grid lg:grid-cols-3 grid-cols-1 text-center lg:text-left gap-4 my-32">
          <div className="... ">
            <div className="border border-2 border-green-400 w-14 lg:mb-5 "></div>
            <h2 className="text-2xl font-bold my-4">OUR SERVICES</h2>
            <p className='mb-5'>
            Tech Click India (TCI) offers multi-vendor solutions in the information technology 
            infrastructure services market. Our products and services are designed to deliver
             end-to-end IT engineering solutions.
            </p>
            <Button size="lg" className="flex items-center text-green-800 border-2 border-orange-700 bg-white lg:mt-5">
                      Learn More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </Button>
          </div>
          <div class="col-span-2 ...">
            <div class="grid grid-cols-1 lg:grid-cols-6 xl:grid-cols-6 gap-2">
              {services.map(({title,link,icon},key) => (
                <div className='justify-self-center text-center rounded-xl shadow-xl bg-white px-3 pt-3 pb-0 mb-2 border-4 hover:border-orange-800'>
                  <a href={link} key={key}>
                    {createElement(icon, {
                      // strokeWidth: 2,
                      className: "ml-6 text-teal-600 ml-3 w-12 h-12",
                    })}
                  <Typography color="blue-gray" className="mb-2 font-bold">
                    {title}
                  </Typography>
                  </a>
                  </div>
                  ))}
            </div>
          </div>
        </div>
        {/* services */}
    </div>
    <div className='container mx-auto px-5'>
      {/* events */}
      {/* <div className='my-32'>
        <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">Events</h2>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 my-6'>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <div className='mb-4'>
                <CalendarDaysIcon className='w-6 float-start mr-2 text-teal-500'/>
                <span className='text-xs mt-1'> June, 29</span>
              </div>
              <Typography variant="h4" color="purple" className="mb-2 hover:text-purple-800">
                Lyft launching cross-platform service this week
              </Typography>
              <Typography color="gray" className="font-normal">
                Like so many organizations these days, Autodesk is a company in
                transition. It was until recently a traditional boxed software company
                selling licenses. Yet its own business model disruption is only part
                of the story
              </Typography>
              <Typography variant='h5' className='italic my-5'>
                Free Entry
              </Typography>
              <a href="#1" className="inline-block">
                <Button variant="text" className="flex items-center gap-2 border-2 border-purple-500">
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </a>
            </CardBody>
          </Card>
          <Card className="overflow-hidden lg:flex-row shadow hover:shadow-xl">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
              <div className='mb-4'>
                <CalendarDaysIcon className='w-6 float-start mr-2 text-teal-500'/>
                <span className='text-xs mt-1'> June, 29</span>
              </div>
              <Typography variant="h4" color="purple" className="mb-2">
                Lyft launching cross-platform service this week
              </Typography>
              <Typography color="gray" className="font-normal">
                Like so many organizations these days, Autodesk is a company in
                transition. It was until recently a traditional boxed software company
                selling licenses. Yet its own business model disruption is only part
                of the story
              </Typography>
              <Typography variant='h5' className='italic my-5'>
                Free Entry
              </Typography>
              <a href="#1" className="inline-block">
                <Button variant="text" className="flex items-center gap-2 border-2 border-purple-500">
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </a>
            </CardBody>
          </Card>
        </div>
        <div className='flex justify-center'>
        <Button size="lg" className='my-6 bg-teal-600'>VIEW ALL EVENTS</Button>
        </div>
      </div> */}
      {/* events */}
      {/* brand area */}
      <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">OUR CLIENTS</h2>
      <div className="grid grid-cols-2 lg:grid-cols-6 gap-4 place-items-center mb-32">
        <div className='border-l mx-auto'><img src={crocs} alt="com_Logo"/></div>
        <div className='border-l mx-auto'><img src={ethos} alt="com_Logo"/></div>
        <div className='border-l mx-auto'><img src={uspolo} alt="com_Logo"/></div>
        <div className='border-l mx-auto'><img src={nykaa} alt="com_Logo"/></div>
        <div className='border-l mx-auto'><img src={kfc} alt="com_Logo"/></div>
        <div className='border-l mx-auto'><img src={pizzahut} alt="com_Logo"/></div>
      </div>
      {/* brand area */}
      {/* latest news */}
      <div className='my-32'>
        <div className="border border-2 border-green-400 w-14 mx-auto"></div>
        <h2 className="text-3xl font-bold my-5 text-center">LATEST NEWS</h2>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 my-6'>
        <Card className="overflow-hidden">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none"
          >
            <img
              src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
              alt="ui/ux review check"
            />
          </CardHeader>
          <CardBody>
          <Chip size="md" value="Catagory" className='w-1/3 lg:w-1/4 mb-3 bg-teal-300'/>
            <Typography variant="h4" color="purple" className='hover:text-purple-800'>
              UI/UX Review Check
            </Typography>
            <Typography variant="sm" color="gray" className="mt-3 font-normal">
              Because it&apos;s about motivating the doers. Because I&apos;m here to
              follow my dreams and inspire others.
            </Typography>
          </CardBody>
          <CardFooter className="flex items-center justify-between pt-0">
            {/* <Typography className="font-normal">January 10</Typography> */}
            <div className=''>
                <CalendarDaysIcon className='w-6 float-start mr-2 text-teal-500'/>
                <span className='text-sm'> June, 29</span>
              </div>
          </CardFooter>
        </Card>
        <Card className="overflow-hidden">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none"
          >
            <img
              src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
              alt="ui/ux review check"
            />
          </CardHeader>
          <CardBody>
          <Chip size="md" value="Catagory" className='w-1/3 lg:w-1/4 mb-3 bg-teal-300'/>
            <Typography variant="h4" color="purple" className='hover:text-purple-800'>
              UI/UX Review Check
            </Typography>
            <Typography variant="sm" color="gray" className="mt-3 font-normal">
              Because it&apos;s about motivating the doers. Because I&apos;m here to
              follow my dreams and inspire others.
            </Typography>
          </CardBody>
          <CardFooter className="flex items-center justify-between pt-0">
            {/* <Typography className="font-normal">January 10</Typography> */}
            <div className=''>
                <CalendarDaysIcon className='w-6 float-start mr-2 text-teal-500'/>
                <span className='text-sm'> June, 29</span>
              </div>
          </CardFooter>
        </Card>
        <Card className="overflow-hidden">
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 rounded-none"
          >
            <img
              src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
              alt="ui/ux review check"
            />
          </CardHeader>
          <CardBody>
          <Chip size="md" value="Catagory" className='w-1/3 lg:w-1/4 mb-3 bg-teal-300'/>
            <Typography variant="h4" color="purple" className='hover:text-purple-800'>
              UI/UX Review Check
            </Typography>
            <Typography variant="sm" color="gray" className="mt-3 font-normal">
              Because it&apos;s about motivating the doers. Because I&apos;m here to
              follow my dreams and inspire others.
            </Typography>
          </CardBody>
          <CardFooter className="flex items-center justify-between pt-0">
            {/* <Typography className="font-normal">January 10</Typography> */}
            <div className=''>
                <CalendarDaysIcon className='w-6 float-start mr-2 text-teal-500'/>
                <span className='text-sm'> June, 29</span>
              </div>
          </CardFooter>
        </Card>
        </div>
        <div className='flex justify-center'>
        <Button size="lg" className='my-6 bg-purple-600'>VIEW ALL NEWS</Button>
        </div>
      </div>
      {/* latest news */}
    </div>  
    <Footer/>
    </>
  )
}

export default Home