import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import acropolisBanner from '../images/acropolisBanner.jpg';
import { Breadcrumbs, Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  CardHeader, } from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import { FaCar } from "react-icons/fa";
import { BsBusFront } from "react-icons/bs";
import bnrimg1 from '../images/banner-img1.jpg';
const servicesItems = [
  {
    title: "CCTV",
    image:"/services#cctv",
    decription: "GiCctvCamera",
    ulpoints: ""
  },
    
];
const Direction = () => {
  return (
    <>
    <Nav/>
    <div className="hidden lg:block blur-sm">
      <img src={bnrimg1} alt="acropolis Banner" className="object-cover h-96 w-full ..."/>
    </div>
    <span className='hidden lg:block absolute top-60 left-[32rem] text-6xl py-8 px-6 border-y-8 border-white-900 text-white font-bold'>Services</span>
    <div className='flex justify-center z-10 my-5'>
      <Breadcrumbs
        separator={
          <ArrowLongRightIcon className="h-4 w-4 text-white" strokeWidth={2.5} />
        }
        className="rounded-full border border-white bg-gradient-to-tr from-gray-900 to-gray-800 p-1"
      >
        <a
          href="/"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          <HomeIcon className='w-5'/>
        </a>
        {/* <a
          href="#"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Components
        </a> */}
        <a
          href="/about-us"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Services
        </a>
      </Breadcrumbs>
    </div>
    <div className='container mx-auto px-5'>
          {/* services-banner-text */}
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10">
            <CardBody>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
      {/* services-banner-text */}

      <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="cctv">
      <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">CCTV</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10"id="networking">
            
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Structured Networking</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="wifi">
      <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Wi-Fi Zone</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="videdoorphone">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Video Door Phone</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="accessControl">
      <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Access Control</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="fias">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Fire & Intrusion Alarm System</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="voipIntercom">
      <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">VoIP & Intercom</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="Digiclass">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Digital Classroom</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="avcon">
      <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Audio & Video Conferencing</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="solar">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Solar Power Solution</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10" id="HomeAutomation">
      <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">Home Automation</Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
            </CardBody>
          </Card>
          
    </div>
    <Footer/>
    </>
  )
}

export default Direction