import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import acropolisBanner from '../images/acropolisBanner.jpg';
import { Breadcrumbs, Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  CardHeader, } from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import { FaCar } from "react-icons/fa";
import { BsBusFront } from "react-icons/bs";
import bnrimg1 from '../images/banner-img2.jpg';

const Direction = () => {
  return (
    <>
    <Nav/>
    <div className="hidden lg:block blur-sm">
      <img src={bnrimg1} alt="acropolis Banner" className="object-cover h-96 w-full ..."/>
    </div>
    <span className='hidden lg:block absolute top-60 left-[32rem] text-6xl py-8 px-6 border-y-8 border-white-900 text-white font-bold'>About Us      </span>
    <div className='flex justify-center z-10 my-5'>
      <Breadcrumbs
        separator={
          <ArrowLongRightIcon className="h-4 w-4 text-white" strokeWidth={2.5} />
        }
        className="rounded-full border border-white bg-gradient-to-tr from-gray-900 to-gray-800 p-1"
      >
        <a
          href="/"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          <HomeIcon className='w-5'/>
        </a>
        {/* <a
          href="#"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Components
        </a> */}
        <a
          href="/about-us"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          About
        </a>
      </Breadcrumbs>
    </div>
    <div className='container mx-auto px-5'>
          {/* aboutus */}
          <Card className=" overflow-hidden lg:flex-row shadow hover:shadow-xl border-8 mt-10">
            <CardBody>
            <Typography variant="h4" className="mb-2 text-orange-800">ABOUT US</Typography>
              <Typography variant="h1" className="mb-2 text-slate-400">
                TECH CLICK INDIA
              </Typography>
              <Typography color="gray" className="font-normal mb-3">
                Tech Click India is a leading technology company dedicated
                 to delivering innovative solutions tailored to modern digital needs.
                  With a focus on cutting-edge software development, web design, and digital
                   marketing services, we empower businesses to thrive in the digital era.
                    Our team of experts combines creativity with technical prowess to craft
                     bespoke solutions that drive results.
                     <br/><br/>
                Whether it's developing intuitive applications or designing captivating websites,
                 we're committed to exceeding client expectations and fostering long-term success.
                  At Tech Click India, we leverage the latest technologies and industry best practices
                   to propel businesses forward, one click at a time.

              </Typography>
              {/* <Typography variant='h5' className='italic my-5'>
                Free Entry
              </Typography> */}
              <a href="#1" className="inline-block">
                <Button variant="text" className="flex items-center text-green-800 border-2 border-orange-800 bg-white">
                  Read More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </a>
            </CardBody>
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 lg:w-2/5 shrink-0 rounded-r-none"
            >
              <img
                src={bnrimg1}
                alt="acropolis events"
                className="h-full w-full object-cover"
              />
            </CardHeader>
          </Card>
      {/* aboutus */}
    </div>
    <div className='grid grid-cols-1 lg:grid-cols-2'>
        <div className='mx-7'>
        <Card className="mt-6 mx-4 w-auto">
          <CardBody>
            <Typography variant="h4" color="gray" className="mb-2">
              <FaCar className='text-6xl inline text-purple-500'/> <span className='ml-2 pb-2 border-b-4 border-teal-400 '>By Car</span>
            </Typography>
            <Typography>
            If driving from the West, From the Gardiner Expressway East, exit at Bay St/York St exit and head north on Yonge. Parking is available at the Yonge & Shuter parkade, or the Bay & Dundas Parkade.

If driving from the East, take the Don Valley Pkwy S. Exit toward Richmond Street/Downtown and take Richmond St. E to Yonge St. Parking. Please contact us if you need additional information on our location.
            </Typography>
          </CardBody>
        </Card>
        <Card className="mt-6 mx-4 w-auto">
          <CardBody>
            <Typography variant="h4" color="gray" className="mb-2">
              <BsBusFront className='text-6xl inline text-purple-500'/> <span className='ml-2 pb-2 border-b-4 border-teal-400 '>By Bus</span>
            </Typography>
            <Typography>
            If driving from the West, From the Gardiner Expressway East, exit at Bay St/York St exit and head north on Yonge. Parking is available at the Yonge & Shuter parkade, or the Bay & Dundas Parkade.

If driving from the East, take the Don Valley Pkwy S. Exit toward Richmond Street/Downtown and take Richmond St. E to Yonge St. Parking. Please contact us if you need additional information on our location.
            </Typography>
          </CardBody>
        </Card>
        </div>
        <div>
        <iframe 
        title='acrpolis map'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.457971138633!2d88.42901297443409!3d26.72975786793093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e441e24da75a5b%3A0xe01e6b0ffbddb00a!2sML%20ACROPOLIS!5e0!3m2!1sen!2sin!4v1707253231923!5m2!1sen!2sin" 
        width="100%" height="750" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Direction