import React from 'react';
import { Carousel } from "@material-tailwind/react";
import bnrimg1 from '../images/banner-img1.jpg';
import bnrimg2 from '../images/banner-img2.jpg';
import bnrimg3 from '../images/banner-img3.jpg';
const Slider = () => {
  return (
    <Carousel 
    // autoplay="true" loop="true" autoplayDelay="3000"
    >
      <div className="relative h-full w-full">
        <img
          src={bnrimg1}
          alt="imag"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="relative h-full w-full">
        <img
          src={bnrimg2}
          alt="imag"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="relative h-full w-full">
        <img
          src={bnrimg3}
          alt="imag"
          className="h-full w-full object-cover"
        />
      </div>
    </Carousel>
  )
}

export default Slider