// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Shop from './pages/Shop';

function App() {
  return (
    <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home /> }/>
                <Route path="/about-us" element={ <About /> }/>
                <Route path="/services" element={ <Services /> }/>
                <Route path="/clients" element={ <Clients /> }/>
                <Route path="/gallery" element={ <Gallery /> }/>
                <Route path="/contact" element={ <Contact /> }/>
                {/* <Route path="/Singleproducts/:id" element={ <Direction /> }/> */}
                <Route path="/shops" element={ <Shop/> }/>
            </Routes>
        </BrowserRouter>
  );
}

export default App;
