import React, { Fragment, createElement, useEffect, useState } from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Bars4Icon,
  GlobeAmericasIcon,
  NewspaperIcon,
  PhoneIcon,
  RectangleGroupIcon,
  SquaresPlusIcon,
  SunIcon,
  TagIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import anl from '../images/tciFullLogo.png';
import { BsInstagram, BsMusicNoteList, BsTwitterX, BsYoutube } from "react-icons/bs";
import { IoFastFoodOutline } from "react-icons/io5";
import { TbParking,TbShoppingBag } from "react-icons/tb";
import { FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
const resourceItems = [
  {
    title: "Products",
    description: "Find the perfect solution for your needs.",
    icon: SquaresPlusIcon,
  },
  {
    title: "About Us",
    description: "Meet and learn about our dedication",
    icon: UserGroupIcon,
  },
  {
    title: "Blog",
    description: "Find the perfect solution for your needs.",
    icon: Bars4Icon,
  },
  {
    title: "Services",
    description: "Learn how we can help you achieve your goals.",
    icon: SunIcon,
  },
  {
    title: "Support",
    description: "Reach out to us for assistance or inquiries",
    icon: GlobeAmericasIcon,
  },
  {
    title: "Contact",
    description: "Find the perfect solution for your needs.",
    icon: PhoneIcon,
  },
  {
    title: "News",
    description: "Read insightful articles, tips, and expert opinions.",
    icon: NewspaperIcon,
  },
  {
    title: "Products",
    description: "Find the perfect solution for your needs.",
    icon: RectangleGroupIcon,
  },
  {
    title: "Special Offers",
    description: "Explore limited-time deals and bundles",
    icon: TagIcon,
  },
];
function ResourceMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const renderItems = resourceItems.map(
    ({ icon, title, description }, key) => (
      <a href="#1" key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
            {" "}
            {createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm font-bold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    ),
  );
 
  return (
    <Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Resources
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block xxl:hidden lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </Fragment>
  );
}
const serviceItems = [
  {
    title: "Shops",
    description: "Shops in Acropolis Mall",
    link:"/shops",
    icon: TbShoppingBag,
  },
  {
    title: "Foods",
    description: "Enjoy your meals at Acropolis",
    link:"/food",
    icon: IoFastFoodOutline,
  },
  {
    title: "Entertainment",
    description: "Entertain yourself at acropolis",
    link:"/entainment",
    icon: BsMusicNoteList,
  },
  {
    title: "Parking",
    description: "Your parking solutions at acropolis",
    link:"/parking",
    icon: TbParking,
  },
  
];
function ServiceMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const renderItems = serviceItems.map(
    ({ icon, title, description, link }, key) => (
      <a href={link} key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg hover:ml-2">
          <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
            {" "}
            {createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-sm font-bold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    ),
  );
 
  return (
    <Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-1 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block xxl:hidden lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </Fragment>
  );
}
 
function NavList() {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as="a"
        href="/"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">Home</ListItem>
      </Typography>
      <Typography
        as="a"
        href="/about-us"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          About Us
        </ListItem>
      </Typography>
      {/* <ServiceMenu /> */}
      <Typography
        as="a"
        href="/services"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Services
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/clients"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Clients
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/gallery"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Gallery
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/contact"
        variant="small"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Contact Us
        </ListItem>
      </Typography>
      {/* <ResourceMenu /> */}
    </List>
  );
}
 
export default function Nav() {
  const [openNav, setOpenNav] = useState(false);
  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);
 
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  return (
    <>
    <div className="lg:grid grid-cols-2 text-white hidden bg-white">
        <div></div>
        <div className="gap-4 flex justify-center py-2 pr-4" id="pointer">
          <div className="flex gap-2 pr-3 border-r-2"><FaPhoneVolume className="mt-1"/>
            <a href="tel:09641666523" target="_blank" rel="noreferrer">9641666523</a></div>
          <div className="flex gap-2 pr-3 border-r-2"> <IoMail className="mt-1"/>
            <a href="mailto:info@techclickindia.com">info@techclickindia.com</a></div>
          <div className="flex gap-2">
            <div className="m-1"><FaFacebookF/></div>
            <div className="m-1"><BsInstagram/></div>
            <div className="m-1"><BsTwitterX/></div>
            <div className="m-1"><BsYoutube/></div>
            <div className="m-1"><FaLinkedinIn/></div>
          </div>
        </div>
      </div>
        <div className="py-2 flex justify-between bg-indigo-600 text-white shadow rounded-b-lg lg:hidden xl:hidden md:hidden xxl:hidden">
          <div className="flex justify-start mx-2">
            <div className="m-1"><a href="tel:09641666523" target="_blank" rel="noreferrer"><FaPhoneVolume/></a></div>
            <div className="m-1"><a href="mailto:info@techclickindia.com" target="_blank" rel="noreferrer"><IoMail/></a></div>
          </div>
          <div className="flex justify-end mx-2">
            <div className="m-1"><FaFacebookF/></div>
            <div className="m-1"><BsInstagram/></div>
            <div className="m-1"><BsTwitterX/></div>
            <div className="m-1"><BsYoutube/></div>
            <div className="m-1"><FaLinkedinIn/></div>
          </div>
        </div>
    
    <Navbar className="rounded-none px-4 mx-auto shadow-none py-0">
      
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2"
        >
          <img src={anl} className="w-48" alt="Acropolis Logo"/>
        </Typography>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <div className="hidden gap-2 lg:flex">
          
        </div>
        <IconButton
          variant="text"
          color="white-indigo"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
    </Navbar>
    </>
  );
}