import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import acropolisBanner from '../images/acropolisBanner.jpg';
import { Breadcrumbs,  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  CardHeader,
  Card, } from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";

const Shop = () => {
  const data = [
    {
      label: "Salon",
      value: "salon",
      shops: [
        {
          link:"/singleshop",
          shopname:"Neel David`s",
          shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/5ac.jpeg?alt=media&token=7972c42b-ce3d-431f-b09a-5dbf5379f4d8",
        },
        {
          link:"/singleshop",
          shopname:"Studex",
          shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/6ac.jpeg?alt=media&token=395da5f2-6bae-40fa-8056-cf6031319206",
        },
      ],
    },
    {
      label: "Cosmetics",
      value: "cosmetics",
      shops: [
        {
          link:"/singleshop",
          shopname:"Sawni",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/9ac.jpeg?alt=media&token=b043eadf-af43-4a3e-9922-851c34974fe0",
        },
        {
          link:"/singleshop",
          shopname:"Nykaa",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/12ac.jpeg?alt=media&token=93a1b376-feaf-4ac3-9088-99fbad9ca3f6",
        },
      ],
    },
    {
      label: "Anchor Shop",
      value: "anchorShop",
      shops: [
        {
          link:"/singleshop",
          shopname:"Shoppers Stop",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/10ac.jpeg?alt=media&token=6c8c3182-c756-42e9-a113-0a72fd69274f",
        },
        
      ],
    },
    {
      label: "Apparels",
      value: "apparels",
      shops: [
        {
          link:"/singleshop",
          shopname:"Aurelia",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/7ac.jpeg?alt=media&token=5060b3f1-9016-404f-b8af-415a0d25cc9c",
        },
        {
          link:"/singleshop",
          shopname:"Q",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/8ac.jpeg?alt=media&token=8790ddcb-5b11-4747-b2e5-c434c8582463",
        },
        
      ],
    },
    {
      label: "Footwear",
      value: "footwear",
      shops: [
        {
          link:"/singleshop",
          shopname:"Metro",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/11ac.jpeg?alt=media&token=fcf58c41-fec8-4e25-873e-bcf8b1782c27",
        },
        
      ],
    },
    {
      label: "Watches",
      value: "watches",
      shops: [
        {
          link:"/singleshop",
          shopname:"Ethos",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://cdn1.ethoswatches.com/media/storelocator/newstore/list/ESAM_2.jpg",
        },
      ],
    },
    {
      label: "Perfumes",
      value: "perfumes",
      shops: [
        {
          link:"/singleshop",
          shopname:"perfume store",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
        },
      ],
    },
    // {
    //   label: "Multiplex",
    //   value: "multiplex",
    //   shops: [
    //     {
    //       imageLink:
    //         "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
    //     },
    //     {
    //       imageLink:
    //         "https://material-taillwind-pro-ct-tailwind-team.vercel.app/img/content2.jpg",
    //     },
    //     {
    //       imageLink:
    //         "https://images.unsplash.com/photo-1620064916958-605375619af8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1493&q=80",
    //     },
    //     {
    //       imageLink:
    //         "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
    //     },
    //     {
    //       imageLink:
    //         "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
    //     },
    //     {
    //       imageLink:
    //         "https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80",
    //     },
    //   ],
    // },
    {
      label: "Foods",
      value: "foods",
      shops: [
        {
          link:"/singleshop",
          shopname:"Error",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/1ac.jpeg?alt=media&token=40625791-a864-4611-bf15-c4b5ebc787b5",
        },
        {
          link:"/singleshop",
          shopname:"Gun Powder",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/2ac.jpeg?alt=media&token=b8d1e3b2-4bbc-4576-a35d-bd110c4c1e87",
        },
        {
          link:"/singleshop",
          shopname:"Berlin CoffeHouse",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/4ac.jpeg?alt=media&token=95002e93-967a-404e-8d6c-6afb574f8207",
        },
      ],
    },
    {
      label: "Food Court",
      value: "FoodCourt",
      shops: [
        {
          link:"/singleshop",
          shopname:"Food Court",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
        },
      ],
    },
    {
      label: "Game Zone",
      value: "gameZone",
      shops: [
        {
          link:"/singleshop",
          shopname:"PlayZone",
          // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/3ac.jpeg?alt=media&token=9c77509d-1f38-40e5-8f9a-c8f6373849c9",
        },
      ],
    },
    {
      label: "Inox",
      value: "inox",
      shops: [
        {link:"/singleshop",
        shopname:"Inox",
        // shoplogo:"https://firebasestorage.googleapis.com/v0/b/acropolis-e227c.appspot.com/o/neeldavids.png?alt=media&token=5c07756c-ded8-4b16-a249-f582568af052",
          imageLink:
            "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
        },
        
      ],
    },
  ];
  return (
    <>
    <Nav/>
    <div className="hidden lg:block blur-sm">
      <img src={acropolisBanner} alt="acropolis Banner" className="object-cover h-96 w-full ..."/>
    </div>
    <span className='hidden lg:block absolute top-60 left-[30rem] text-8xl text-white font-bold'>Shops</span>
    <div className='flex justify-center z-10 py-5'>
      <Breadcrumbs
        separator={
          <ArrowLongRightIcon className="h-4 w-4 text-white" strokeWidth={2.5} />
        }
        className="rounded-full border border-white bg-gradient-to-tr from-gray-900 to-gray-800 p-1"
      >
        <a
          href="/"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          <HomeIcon className='w-5'/>
        </a>
        {/* <a
          href="#"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Components
        </a> */}
        <a
          href="/shops"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Shops
        </a>
      </Breadcrumbs>
    </div>
    <div className='container mx-auto px-2 lg:px-8'>
    <Tabs value="salon">
      <TabsHeader>
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="grid grid-cols-1 gap-4 ">
        {data.map(({ value, shops }) => (
          <TabPanel
            className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 overflow-x-scroll ..."
            key={value}
            value={value}
          >
            
            {/* <Card className="relative grid h-[18rem] w-full  items-end justify-center text-center">
            <CardHeader 
              className="absolute inset-0 m-0 w-100 rounded-lg bg-[url('https://images.unsplash.com/photo-1495474472287-4d71bcdd2085?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-center"
            >
               <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/70 via-black/50" />
               <div className='absolute z-10 text-white top-[170px] pl-8'>
                 <p className='font-bold text-2xl'>Save 30%</p>
                 <p>at all jwellery stores of Acropolis Mall</p>
               </div>
            </CardHeader>
          </Card> */}
            {shops?.map(({ imageLink,link, shopname, shoplogo }, index) => (
              <div key={index} className='bg-grey-200 rounded-xl border-b-2 hover:shadow-md border-gray-500 shadow-xl'>
                <a href={link}>
                <img
                  className="h-40 w-full max-w-full rounded-lg object-cover object-center shadow-md"
                  src={imageLink}
                  alt={shopname}
                />
                {/* <div className='border-2 '> */}
                  <img className='rounded-full w-14 lg:w-20 py-5 mx-auto' src={shoplogo} alt={shopname}/>
                {/* </div> */}
                <h5 className='flex justify-center text-black font-bold pb-5'>{shopname}</h5>
                </a>
              </div>
            ))}
            
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
    </div>
    <Footer/>
    </>
  )
}

export default Shop