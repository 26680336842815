import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import acropolisBanner from '../images/acropolisBanner.jpg';
import { Breadcrumbs, Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  CardHeader,
  Checkbox,
  Input, } from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import { FaCar } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import bnrimg1 from '../images/banner-img1.jpg';

const Direction = () => {
  return (
    <>
    <Nav/>
    <div className="hidden lg:block blur-sm">
      <img src={bnrimg1} alt="acropolis Banner" className="object-cover h-96 w-full ..."/>
    </div>
    <span className='hidden lg:block absolute top-60 left-[32rem] text-6xl py-8 px-6 border-y-8 border-white-900 text-white font-bold'>Contact Us</span>
    <div className='flex justify-center z-10 my-5'>
      <Breadcrumbs
        separator={
          <ArrowLongRightIcon className="h-4 w-4 text-white" strokeWidth={2.5} />
        }
        className="rounded-full border border-white bg-gradient-to-tr from-gray-900 to-gray-800 p-1"
      >
        <a
          href="/"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          <HomeIcon className='w-5'/>
        </a>
        {/* <a
          href="#"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Components
        </a> */}
        <a
          href="/contact"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Contact
        </a>
      </Breadcrumbs>
    </div>
    <div className='grid grid-cols-1 lg:grid-cols-2'>
        <div className='mx-7'>
        <Card className="mt-6 mx-4 w-auto">
          <CardBody>
            <Typography color="gray" className="mb-2">
            To reach to us, please fill the form below and send across. You may also drop your requirment here.
            </Typography>
            <Card color="transparent" shadow={false}>
      <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
        <div className="mb-1 flex flex-col gap-6">
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            Your Name
          </Typography>
          <Input
            size="lg"
            placeholder="name@mail.com"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            Your Email
          </Typography>
          <Input
            size="lg"
            placeholder="name@mail.com"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            Phone
          </Typography>
          <Input
            type="number"
            size="lg"
            placeholder="9876543210"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography variant="h6" color="blue-gray" className="-mb-3">
            Your Messege
          </Typography>
          <Input
            size="lg"
            placeholder="Type your Query here"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
        </div>
        
        <Button className="mt-6" fullWidth>
          Submit
        </Button>
      </form>
    </Card>
          </CardBody>
        </Card>
        <Card className="mt-6 mx-4 w-auto">
          <CardBody>
            <Typography variant="h4" color="gray" className="mb-2">
              <HiOfficeBuilding className='text-4xl inline text-purple-500'/> <span className='ml-2 pb-2 border-b-4 border-teal-400 '>Regd. Office</span>
            </Typography>
            <Typography>
            Block-GP, Plot No. – F4, Salt Lake Sector-V,
            Near Fire Brigade, Arch WaterFront Building,
            05th Floor, Kolkata-700091
            </Typography>
            <span><b>Phone: </b>+91 (033) 2357 0111, +91 98360 10030</span><br/>
            <span><b>Fax: </b>+91 98360 10030</span><br/>
            <span><b>Email: </b>mail@domain.com</span>
          </CardBody>
        </Card>
        </div>
        <div>
        <iframe 
        title='acrpolis map'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.457971138633!2d88.42901297443409!3d26.72975786793093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e441e24da75a5b%3A0xe01e6b0ffbddb00a!2sML%20ACROPOLIS!5e0!3m2!1sen!2sin!4v1707253231923!5m2!1sen!2sin" 
        width="100%" height="750" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Direction