import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import acropolisBanner from '../images/acropolisBanner.jpg';
import { Breadcrumbs, Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  CardHeader, } from "@material-tailwind/react";
  import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
  } from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";
import { FaCar } from "react-icons/fa";
import { BsBusFront } from "react-icons/bs";
import bnrimg1 from '../images/banner-img2.jpg';

const data = [
  {
    label: "HTML",
    value: "html",
    images: [
      {
        imageLink:
          "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80",
      },
      {
        imageLink:
          "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
      },
      {
        imageLink:
          "https://material-taillwind-pro-ct-tailwind-team.vercel.app/img/content2.jpg",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1620064916958-605375619af8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1493&q=80",
      },
    ],
  },
  {
    label: "React",
    value: "react",
    images: [
      {
        imageLink:
          "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80",
      },
    ],
  },
  {
    label: "Vue",
    value: "vue",
    images: [
      {
        imageLink:
          "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80",
      },
      {
        imageLink:
          "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
      },
      {
        imageLink:
          "https://material-taillwind-pro-ct-tailwind-team.vercel.app/img/content2.jpg",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1620064916958-605375619af8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1493&q=80",
      },
    ],
  },
  {
    label: "Angular",
    value: "angular",
    images: [
      {
        imageLink:
          "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80",
      },
    ],
  },
  {
    label: "Svelte",
    value: "svelte",
    images: [
      {
        imageLink:
          "https://demos.creative-tim.com/material-kit-pro/assets/img/examples/blog5.jpg",
      },
      {
        imageLink:
          "https://material-taillwind-pro-ct-tailwind-team.vercel.app/img/content2.jpg",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1620064916958-605375619af8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1493&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80",
      },
      {
        imageLink:
          "https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80",
      },
    ],
  },
];
const Gallery = () => {
  return (
    <>
    <Nav/>
    <div className="hidden lg:block blur-sm">
      <img src={bnrimg1} alt="acropolis Banner" className="object-cover h-96 w-full ..."/>
    </div>
    <span className='hidden lg:block absolute top-60 left-[32rem] text-6xl py-8 px-6 border-y-8 border-white-900 text-white font-bold'>Gallery</span>
    <div className='flex justify-center z-10 my-5'>
      <Breadcrumbs
        separator={
          <ArrowLongRightIcon className="h-4 w-4 text-white" strokeWidth={2.5} />
        }
        className="rounded-full border border-white bg-gradient-to-tr from-gray-900 to-gray-800 p-1"
      >
        <a
          href="/"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          <HomeIcon className='w-5'/>
        </a>
        {/* <a
          href="#"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
          Components
        </a> */}
        <a
          href="/gallery"
          className="rounded-full bg-white px-3 py-1 font-medium text-gray-900"
        >
         gallery
        </a>
      </Breadcrumbs>
    </div>
        <div className='mx-7'>
        <Tabs value="html">
      <TabsHeader>
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="grid grid-cols-1 gap-4 ">
        {data.map(({ value, images }) => (
          <TabPanel
            className="grid grid-cols-2 gap-4 md:grid-cols-5"
            key={value}
            value={value}
          >
            {images?.map(({ imageLink }, index) => (
              <div key={index}>
                <img
                  className="h-40 w-full max-w-full rounded-lg object-cover object-center"
                  src={imageLink}
                  alt="image-photo"
                />
              </div>
            ))}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
    </div>
    <Footer/>
    </>
  )
}

export default Gallery